<template>
  <b-card>
    <vue-confirm-dialog />
    <b-row>
      <b-col class="text-center">
        <h6>ITENS DO PEDIDO</h6>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="position-relative">
          <vuetable
            ref="vuetable"
            :api-mode="false"
            :data="items"
            :fields="camposCarrinho"
          >

            <div
              slot="opcao-preco"
              slot-scope="item"
            >
              {{ item.rowData.price | currency }}
            </div>
            <div
              slot="opcao-qtd"
              slot-scope="item"
            >
              <b-input-group>
                <b-input-group-prepend>
                  <b-btn
                    variant="info"
                    @click="decrement(item.rowData.id)"
                  >
                    -
                  </b-btn>
                </b-input-group-prepend>

                <b-form-input
                  type="number"
                  min="0.00"
                  :value="item.rowData.qtd"
                  @input="atualizaValor(item.rowData.id, $event)"
                />

                <b-input-group-append>
                  <b-btn
                    variant="info"
                    @click="increment(item.rowData.id)"
                  >
                    +
                  </b-btn>
                </b-input-group-append>
              </b-input-group>
            </div>
          </vuetable>
        </div>
      </b-col>
    </b-row>
    <b-card-footer class="fundo-compradores pt-2 pb-0 pl-0 pr-0">
      <b-row>
        <b-col
          md="6"
        >
          <b-button
            v-if="$can('caixa') | ('adm')"
            @click="excluirPedido()"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span class="align-middle">Excluir Pedido</span>
          </b-button>
        </b-col>
        <b-col
          md="6"
          align="right"
        >
          <b-button
            v-if="$can('vendedor') | ('adm')"
            variant="gradient-danger"
            @click="cancelarPedido()"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span class="align-middle">Limpar Pedido</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>

<script>
import Vuetable from 'vuetable-2'
import atualizaItensPedido from '@/helpers/atualizaItensPedido'

export default {
  name: 'PedidoCliente',
  components: {
    Vuetable,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['produtos'],
  data() {
    return {
      items: this.produtos,
      mostraPopUp: true,
      camposCarrinho: [
        {
          name: 'opcao-qtd',
          title: 'Quantidade',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
          width: '25%',
        },
        {
          key: 'nome',
          name: 'name',
          width: '60%',
        },
        {
          name: 'opcao-preco',
          title: 'Preço',
          width: '15%',
        },
      ],
      quantity: 1,
    }
  },
  methods: {
    atualizaItens(itens) {
      this.items = itens
    },
    cancelarPedido() {
      this.$confirm(
        {
          message: 'Deseja realmente cancelar todo o pedido?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: confirm => {
            if (confirm) {
              this.items = []
              localStorage.removeItem('desconto')
              localStorage.removeItem('itensPedido')
              this.$emit('limpar-pedido-cliente', true)
            }
          },
        },
      )
    },
    excluirPedido() {
      this.$confirm(
        {
          message: 'Deseja realmente Excluir todo o pedido? não pode desfazer',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: async confirm => {
            if (confirm) {
              const pedidoId = await JSON.parse(localStorage.getItem('itensPedido')).pedido.id
              this.items = []
              localStorage.removeItem('itensPedido')
              localStorage.removeItem('desconto')
              await this.$http.delete(`orders/delete/${pedidoId}`)
              this.$emit('limpar-pedido-cliente', true)
            }
          },
        },
      )
    },
    async atualizaValor(valor, qtd) {
      const keyItem = this.items.findIndex(x => x.id === valor)
      if (qtd < 1) {
        return this.$confirm({
          message: 'Você gostaria de remover o produto?',
          button: { no: 'Não', yes: 'Sim' },
          // eslint-disable-next-line no-unused-vars
          callback: async confirm => {
            if (confirm) {
              this.items.splice(keyItem, 1)
              await atualizaItensPedido('produtos', this.items)
              this.$emit('atualizapdv', true)
            } else {
              this.items[keyItem].qtd = 1
              await atualizaItensPedido('produtos', this.items)
              this.$emit('atualizapdv', true)
            }
          },
        })
      }
      // eslint-disable-next-line radix
      this.items[keyItem].qtd = parseInt(qtd)
      await atualizaItensPedido('produtos', this.items)
      this.$emit('atualizapdv', true)
      return true
    },
    // eslint-disable-next-line no-unused-vars
    async increment(idPedido) {
      const keyItem = this.items.findIndex(x => x.id === idPedido)
      // eslint-disable-next-line operator-assignment
      this.items[keyItem].qtd = this.items[keyItem].qtd + 1
      await atualizaItensPedido('produtos', this.items)
      this.$emit('atualizapdv', true)
    },
    async decrement(idPedido) {
      const keyItem = this.items.findIndex(x => x.id === idPedido)
      // eslint-disable-next-line operator-assignment
      const quantidade = this.items[keyItem].qtd - 1
      if (quantidade === 0) {
        this.$confirm({
          message: 'Você gostaria de remover o produto?',
          button: { no: 'Não', yes: 'Sim' },
          callback: async confirm => {
            if (confirm) {
              this.items.splice(keyItem, 1)
              await atualizaItensPedido('produtos', this.items)
              this.$emit('atualizapdv', true)
            }
          },
        })
      }
      if (quantidade > 0) { this.items[keyItem].qtd = quantidade }
      await atualizaItensPedido('produtos', this.items)
      this.$emit('atualizapdv', true)
    },
  },
}

</script>

<style scoped>

  .parcela
  {
    height: 37px;
    width: 37px;
    border: solid;
    border-color: #d4d4d4;
    border-radius: 7px 0px 0px 7px;
    border-width: 1px;
    padding-top: 8px;
    text-align: center;
  }
  .parcela2
  {
    height: 37px;
    width: 37px;
    border-left: none;
    border: solid;
    border-color: #d4d4d4;
    border-radius: 0 7px 7px 0;
    border-width: 1px;
    padding-top: 8px;
    text-align: center;
  }
  .parcelas
  {
    height: 37px;
    width: 37px;
    border: solid;
    border-left: none;
    border-color: #d4d4d4;
    border-width: 1px;
    padding-top: 8px;
    text-align: center;
  }

  .itens-cliente {
    height: 200px;
  }

</style>
