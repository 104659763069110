<template>
  <b-row>
    <b-col md="11">
      <b-form-group
        :label="placeh"
        :label-for="lb"
      >
        <multiselect
          :id="lb"
          v-model="model"
          :disabled="model.length > 0"
          label="name"
          track-by="code"
          :placeholder="placeh"
          open-direction="bottom"
          :options="opcoes"
          :multiple="true"
          :searchable="true"
          :show-no-results="true"
          :loading="isLoading"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="false"
          :options-limit="300"
          :limit="3"
          :max-height="600"
          :hide-selected="true"
          @search-change="buscacliente($event)"
          @select="selecionou($event)"
        >
          <template
            slot="tag"
            slot-scope="{ option }"
          >
            <span class="custom__tag"><span>{{ option.name }}</span></span>
          </template>
          <template
            slot="clear"
          >
            <div
              v-if="model.length"
              class="multiselect__clear"
            />
          </template>
        </multiselect>
      </b-form-group>
    </b-col>
    <b-col
      v-if="model.length > 0"
      md="1"
      style="margin-top: 30px; cursor: pointer"
      @click="limparModel"
    >
      ❌
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'MultiSelectBusca',
  // eslint-disable-next-line vue/require-prop-types
  props: ['nome', 'placeh', 'lb'],
  data() {
    return {
      model: '',
      opcoes: [],
      isLoading: false,
      telefone: '',
      email: '',
      nomeCliente: '',
      nomeBusca: '',
    }
  },
  methods: {
    limparModel() {
      this.model = ''
      this.$emit('apagouselect', true)
    },
    selecionou(evento) {
      this.$emit('selecionou', evento, this.nomeBusca)
    },
    async buscacliente(evento) {
      this.nomeBusca = evento
      this.isLoading = true
      const { data: cliente } = await this.$http.get(`customers/search?search=${evento}`)
      if (cliente.length === 0) this.opcoes = [{ id: null, name: 'Cadastrar Cliente' }]
      else this.opcoes = cliente
      this.isLoading = false
    },
  },
}
</script>

<style scoped>

</style>
