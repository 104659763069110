<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row class="mb-2">
          <b-col md="4">
            <b-input
              :value="pesquisaInicio"
              placeholder="Pesquisar Inicio"
              @input="pesquisarInicio($event)"
            />
          </b-col>
          <b-col md="3">
            <b-input
              :value="pesquisaMeio"
              placeholder="Pesquisar qualquer parte"
              @input="pesquisarMeio($event)"
            />
          </b-col>
          <b-col md="4">
            <b-row>
              <b-col
                md="6"
                style="margin-left: -4%"
              >
                <b-form-checkbox
                  v-model="opcoesPesquisa"
                  value="nome"
                >
                  Nome
                </b-form-checkbox>
              </b-col>
              <b-col
                md="6"
                style="margin-left: 4%"
              >
                <b-form-checkbox
                  v-model="opcoesPesquisa"
                  value="marca"
                >
                  Marca
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="6"
                style="margin-left: -4%"
              >
                <b-form-checkbox
                  v-model="opcoesPesquisa"
                  value="desc_longa"
                >
                  Código Etiqueta
                </b-form-checkbox>
              </b-col>
              <b-col
                md="6"
                style="margin-left: 4%"
              >
                <b-form-checkbox
                  v-model="opcoesPesquisa"
                  value="observacoes"
                >
                  Código Fabricante
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="1"
            style="text-align: right;"
          >
            <b-button
              variant="flat-black"
              class="btn-icon"
              @click="abrirOpcoesProdutos"
            >
              <feather-icon icon="AlignJustifyIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-row>

          <b-col>
            <div
              class="position-relative"
              style="line-height: 10px; font-size: 13px;"
            >
              <b-table
                id="table-id"
                ref="tabela-produtos"
                :items="produtos"
                :fields="fieldsProdutos"
                sticky-header
                responsive
                bordered
                no-border-collapse
                show-empty
                primary-key="id"
                @row-dblclicked="pedirItem"
              >
                <template #cell(qtd)="{item}">
                  {{ item.stock ? item.stock.quantity ? item.stock.quantity : 'sincronizando . . .' : 'Migrando . . .' }}
                </template>
                <template #cell(price)="{item}">
                  {{ item.price | currency }}
                </template>
                <template #cell(complemento)="{item}">
                  <feather-icon
                    icon="AlignCenterIcon"
                    size="21"
                    style="cursor: pointer;"
                    @click="VerComplemento(item)"
                  />
                </template>
              </b-table>
              <b-overlay
                :show="estaCarregando"
                no-wrap
                opacity="0.5"
              />
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-modal
      id="complemento"
      title="INFORMAÇÕES ADICIONAIS"
      ok-only
      ok-title="Fechar"
    >
      <b-row>
        <b-form-textarea
          id="textarea-default"
          v-model="complemento.desc_longa"
          disabled
          placeholder=""
          rows="3"
        />
      </b-row>
    </b-modal>
    <modal-opcoesprodutos @opcoestabela="atualizaLabel" />
  </b-row>
</template>

<script>
import ModalOpcoesprodutos from './modal-opcoesprodutos.vue'

export default {
  name: 'TabelaProdutos',
  components: {
    ModalOpcoesprodutos,
  },
  data() {
    return {
      mostraProdutos: true,
      fieldsProdutos: [],
      items: [],
      camposSelecionados: '',
      produtos: [],
      pesquisaInicio: '',
      pesquisaMeio: '',
      currentPage: 0,
      perPage: 10,
      complemento: '',
      estaCarregando: false,
      carregaPagina: false,
      proximaPagina: null,
      proxPaginaPesquisa: null,
      inicio: true,
      opcoesPesquisa: ['nome'],
      // opcoesPesquisa: ['nome', 'desc_curta', 'desc_longa', 'observacoes'],
      nome: true,
      desc_curta: true,
      desc_longa: true,
      obs: true,
      lastPage: 1,
    }
  },
  watch: {
    /* Optionally hide scrollbar when loading */
    estaCarregando(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs['tabela-produtos'].$el
        if (newVal === true) {
          tableScrollBody.classList.add('overflow-hidden')
        } else {
          tableScrollBody.classList.remove('overflow-hidden')
        }
      }
    },
  },
  created() {
    this.fieldsProdutos = JSON.parse(localStorage.getItem('opcoes_menu' || []))
    this.carregaProdutos()
    this.inicio = false
  },
  mounted() {
    const tableScrollBody = this.$refs['tabela-produtos'].$el
    /* Consider debouncing the event call */
    tableScrollBody.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    /* Clean up just to be sure */
    const tableScrollBody = this.$refs['tabela-produtos'].$el
    tableScrollBody.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    VerComplemento(item) {
      this.complemento = item
      this.$bvModal.show('complemento')
    },
    atualizaLabel(valor) {
      this.fieldsProdutos = valor
    },
    abrirOpcoesProdutos() {
      this.$bvModal.show('opcoesprodutos')
    },
    pesquisarInicio(evento) {
      this.pesquisaInicio = evento
      this.pesquisarProd()
    },
    pesquisarMeio(evento) {
      this.pesquisaMeio = evento
      this.pesquisarProd()
    },
    async pesquisarProd() {
      this.estaCarregando = true
      let url = 'products/getall'
      if (this.proxPaginaPesquisa && this.carregaPagina) url = this.proxPaginaPesquisa
      await this.$http.get(`${url}?searchb=${this.pesquisaInicio}&searchm=${this.pesquisaMeio}&options=${this.opcoesPesquisa}`).then(resp => {
        this.proxPaginaPesquisa = resp.data.next_page_url
        this.produtos = resp.data.data
      })
      this.estaCarregando = false
      this.carregaPagina = false
      return true
    },
    pedirItem(record) {
      this.$emit('add-cart', record)
    },
    async carregaProdutos() {
      this.carregaPagina = true
      // Coloca loop de carregando
      if (this.pesquisaInicio.length > 0 || this.pesquisaMeio.length > 0) return this.pesquisarProd()
      this.estaCarregando = true
      let url = 'products/getall'
      if (this.proximaPagina) url = this.proximaPagina
      if (this.currentPage !== this.lastPage) {
        await this.$http.get(url).then(resp => {
          this.proximaPagina = resp.data.next_page_url
          this.currentPage = resp.data.current_page
          this.lastPage = resp.data.last_page
          resp.data.data.forEach(resposta => {
            this.produtos.push(resposta)
          })
        })
      } else {
        this.$toast.error('Ultima Pagina de produtos')
      }
      this.estaCarregando = false
      this.carregaPagina = false
      return true
    },
    onScroll(event) {
      if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
        if (!this.estaCarregando) {
          this.carregaProdutos()
        }
      }
    },
  },
}

</script>

<style scoped>
[dir] .table td {
  line-height: 1.4 !important;
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 225px;
}

</style>
