<template>
  <b-modal
    id="fecharpedido"
    centered
    style="margin-top: -30px"
    title="Finalizar Pedido"
    hide-footer
    @hide="fechouModal"
  >
    <b-row>
      <b-col>
        <h2>Cliente</h2>
      </b-col>
    </b-row>
    <multi-select-busca
      placeh="Efetuar Pesquisa"
      lb="pesquisa"
      @selecionou="recebeuCliente"
      @apagouselect="apSelect"
    />
    <div
      v-if="mostraclienteP"
      style="margin-top: -5px"
    >
      <b-badge
        style="font-weight: 500; padding: 5px 15px; cursor: pointer"
        variant="info"
        @click="clientePadrao"
      >
        Cliente Padrão
      </b-badge>
    </div>
    <b-row
      v-if="mostraCadastro"
      class="pt-2"
    >
      <b-col md="6">
        <b-form-group
          label="CPF/CNPJ do Cliente"
          label-for="cpf"
        >
          <b-form-input
            id="cpf"
            v-model="clienteObj.document"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
            :disabled="clienteObj.id === 1"
            placeholder="CPF/CNPJ"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Nome do Cliente"
          label-for="nome"
        >
          <b-form-input
            id="nome"
            v-model="clienteObj.name"
            :disabled="clienteObj.id === 1"
            placeholder="Nome do Cliente"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Telefone do Cliente"
          label-for="telefone"
        >
          <b-form-input
            id="telefone"
            v-model="clienteObj.phone"
            v-mask="['(##)#####-####', '(##)####-####']"
            :disabled="clienteObj.id === 1"
            placeholder="Telefone"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="E-mail do Cliente"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="clienteObj.email"
            :disabled="clienteObj.id === 1"
            placeholder="E-mail do Cliente"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <b-row class="mt-3 mb-1">
            <b-col
              v-if="addEndEntrega"
              md="6"
            >
              <h2>Entrega</h2>
            </b-col>
            <b-col md="6">
              <b-form-checkbox
                v-model="addEndEntrega"
                :disabled="clienteObj.id === 1"
              >
                Adicionar End Entrega ?
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row v-if="addEndEntrega">
            <b-col>
              <b-form-group
                label="Rua para Entrega (com numero)"
                label-for="endereco"
              >
                <b-form-input
                  id="endereco"
                  v-model="clienteObj.endereco"
                  placeholder="Rua exemplo, 1256"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Contato para entrega"
                label-for="contato"
              >
                <b-form-input
                  id="contato"
                  v-model="clienteObj.contato_entrega"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  placeholder="Telefone"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="addEndEntrega">
            <b-col>
              <b-form-group
                label="CEP"
                label-for="cep"
              >
                <b-form-input
                  id="cep"
                  v-model="clienteObj.cep"
                  v-mask="['#####-###']"
                  placeholder="CEP"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Referência"
                label-for="Referencia"
              >
                <b-form-input
                  id="Referencia"
                  v-model="clienteObj.referencia"
                  placeholder="Ex: casa, apto ..."
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col />
          </b-row>
          <b-row>
            <b-col />
          </b-row>
          <div>
            <b-row
              class="mt-2"
              style="margin-bottom: 8px"
            >
              <b-col>
                <b-button
                  v-if="
                    (podeFiado && !$can('vendedor')) ||
                      (podeFiado && $can('admin'))
                  "
                  block
                  class="botao-exe"
                  @click="finalizarPedido(2, 11)"
                >
                  Criar pedido fiado
                </b-button>
                <b-button
                  v-if="!podeFiado"
                  :disabled="!podeFinalizar"
                  variant="success"
                  block
                  @click="finalizarPedido(1, 1)"
                >
                  Finalizar Pedido
                </b-button>
              </b-col>
              <b-col style="padding-left: 0">
                <b-button
                  id="popover-button-3"
                  :disabled="!podeFinalizar"
                  class="botao-exe"
                  block
                  @click="show = !show"
                >
                  Gerar orçamento
                </b-button>
                <b-popover
                  :show.sync="show"
                  target="popover-button-3"
                  placement="top"
                  triggers="click"
                >
                  <b-row>
                    <b-col>
                      <b-button
                        :disabled="!podeFinalizar"
                        class="botao-exe"
                        block
                        @click="finalizarPedido(2, 10)"
                      >
                        Imprimir
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-button
                        :disabled="!podeFinalizar"
                        class="botao-exe"
                        block
                        @click="finalizarPedido(3, 10)"
                      >
                        PDF
                      </b-button>
                    </b-col>
                  </b-row>
                </b-popover>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  v-if="podeFiado"
                  :disabled="!podeFinalizar"
                  variant="success"
                  block
                  @click="finalizarPedido(1, 1)"
                >
                  Finalizar Pedido
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import 'vue-loading-overlay/dist/vue-loading.css'
import { VBPopover, BButton, BPopover } from 'bootstrap-vue'
import carregaResumoPedido from '@/helpers/carregaResumoPedido'
import MultiSelectBusca from './comp/MultiSelectBusca.vue'

export default {
  name: 'ModalFecharpedido',
  components: {
    MultiSelectBusca,
    BPopover,
    BButton,
  },
  directives: {
    'b-popover': VBPopover,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['tpedido', 'pdesc', 'pparcelas', 'imp'],
  data() {
    return {
      show: false,
      podeFinalizar: false,
      podeFiado: false,
      isLoading: false,
      fullPage: true,
      id: null,
      valorPago: this.tpedido,
      mostraPopUp: false,
      telefoneCliente: '',
      emailCliente: '',
      mostraCadastro: false,
      cCPF: '',
      cNome: '',
      cTELEFONE: '',
      cEMAIL: '',
      endereco: '',
      contato_entrega: '',
      referencia: '',
      pedido_id: '',
      addEndEntrega: false,
      mostraNota: false,
      carteiraFiado: 0,
      dadosNota: {},
      vendedor: '',
      clienteT: [],
      mostraclienteP: true,
      orcamento: false,
      objeto: [],
      cliente: {},
      clienteObj: {
        cep: null,
        contato_entrega: null,
        cpf: '00000000000',
        email: 'Sem E-mail',
        endereco: null,
        id: 1,
        name: 'Consumidor Padrão',
        referencia: null,
        telefone: '99999999999',
      },
      way: 'create',
    }
  },
  async created() {
    this.LimparCampos()
    this.pegaDetalhesPedido()
    this.vendedor = await JSON.parse(localStorage.getItem('userData')).fullName
    await this.buscaImpressora(2)
  },
  methods: {
    clientePadrao() {
      this.mostraclienteP = false
      const cliente = {
        cep: null,
        contato_entrega: null,
        document: 11111111111,
        email: 'Sem E-mail',
        endereco: null,
        id: 5,
        name: 'Consumidor Padrão',
        referencia: null,
        phone: '99999999999',
      }
      this.recebeuCliente(cliente)
    },
    fechouModal() {
      this.mostraclienteP = false
      this.podeFiado = false
      this.mostraCadastro = false
      this.podeFinalizar = false
      this.carteiraFiado = 0
      this.clienteObj = {
        cep: null,
        contato_entrega: null,
        cpf: '00000000000',
        email: 'Sem E-mail',
        endereco: null,
        id: 1,
        name: 'Consumidor Padrão',
        referencia: null,
        telefone: '99999999999',
      }
    },
    pegaDetalhesPedido() {
      this.mostraclienteP = true
      this.carteiraFiado = 0
      const jsonPedidoCliente = JSON.parse(localStorage.getItem('itensPedido')) || {}
      this.mostraclienteP = true
      if (this.podeFiado === true) this.carteiraFiado = 1
      if (jsonPedidoCliente.cliente) {
        const cli = jsonPedidoCliente.cliente
        this.clienteObj = {
          cep: cli.zipCode,
          contato_entrega: cli.contato_entrega,
          cpf: cli.document,
          email: cli.email,
          endereco: cli.street,
          id: cli.id,
          name: cli.name,
          referencia: cli.complement,
          telefone: cli.phone,
        }
        this.mostraCadastro = true
        this.podeFinalizar = true
        this.mostraclienteP = false
        this.carteiraFiado = jsonPedidoCliente.wallet
      }
    },
    apSelect() {
      this.fechouModal()
      this.mostraclienteP = true
    },
    async recebeuCliente(cliente, palavraBusca) {
      // eslint-disable-next-line no-param-reassign
      if (!cliente.id) cliente.name = palavraBusca
      if (cliente) {
        const { data: dados } = await this.$http.get(`customers/find/${cliente.id}`)
        this.clienteT = dados
        if (dados.wallet === 1) this.podeFiado = true
        if (dados.wallet === 0) this.podeFiado = false
      }
      this.mostraCadastro = true
      this.podeFinalizar = true
      this.clienteObj = cliente
    },
    async finalizarPedido(impressora, statusId) {
      const jsonPedidoCliente = await carregaResumoPedido()
      const resumoPedido = JSON.parse(localStorage.getItem('resumopedido'))
      const objeto = {
        statusId,
        cliente: this.clienteObj,
        todosprodutos: jsonPedidoCliente.produtos,
        total_pedidos: resumoPedido.pedidototal,
        pedidodesconto: resumoPedido.pedidodesconto,
        qtdparcelas: resumoPedido.qtdparcelas,
        valorparcela: resumoPedido.valorparcela,
        carteira: this.carteiraFiado,
      }
      if (jsonPedidoCliente.pedido) {
        objeto.pedidoId = jsonPedidoCliente.pedido.id
        this.way = 'update'
      }
      this.$bvModal.hide('fecharpedido')
      localStorage.setItem('resumopedido', JSON.stringify({}))
      this.$loading(true)
      await this.$http.post(`orders/${this.way}`, objeto).then(async resp => {
        if (resp.data.success) {
          this.$toast.success(resp.data.success)
          this.pedido_id = resp.data.pedidoId
          localStorage.removeItem('itensPedido')
          localStorage.removeItem('resumopedido')
          await this.$emit('atualizapdv', true)
          await this.$emit('limpapedido')
        }
        if (resp.data.error) this.$toast.success(resp.data.error)
      })
      this.$loading(false)
      this.$emit('adicionaPedidoAoCarrinho', true)
      await localStorage.setItem('pedido_id', JSON.stringify(this.pedido_id))
      this.LimparCampos()
      this.$emit('recarregaTabelaPedidos', true)
      await this.funcaoImprimir(this.pedido_id, impressora)
    },
    async funcaoImprimir(idPedido, impressora) {
      const imp = await this.buscaImpressora(impressora)
      const { data } = await this.$http.post('printer/order', {
        tipo: impressora,
        pedido: idPedido,
      })
      // eslint-disable-next-line no-undef
      const config = qz.configs.create(imp)
      // eslint-disable-next-line no-undef
      await qz.print(config, [
        {
          type: 'pixel',
          format: 'html',
          flavor: 'plain',
          data,
        },
      ])
    },
    async buscaImpressora(impressora) {
      const impressorapadrao = await JSON.parse(
        localStorage.getItem('impressao') || '[]',
      )
      const impFiado = impressorapadrao.find(imp => imp.id === impressora)
      if (!impFiado) return this.$toast.error('Falta configurar a Impressora de Venda')
      return impFiado.padrao
    },
    LimparCampos() {
      this.clienteObj = {
        cep: null,
        contato_entrega: null,
        cpf: 'Sem CPF',
        email: 'Sem E-mail',
        endereco: null,
        id: 1,
        name: 'Consumidor Padrão',
        referencia: null,
        telefone: '99999999999',
      }
      this.mostraCadastro = false
      this.addEndEntrega = false
    },
  },
}
</script>

<style scoped>
</style>
