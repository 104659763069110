import ItensPedido from '@/helpers/carregaItensPedido'

async function atualizaItensPedido(item, obj) {
  if (item === 'produtos') {
    const itens = await ItensPedido()
    itens.produtos = obj
    await localStorage.setItem('itensPedido', JSON.stringify(itens))
  }
}

export default atualizaItensPedido
