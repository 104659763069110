<template>
  <div>
    <b-col>
      <div class="p-1">
        <b-button
          :to="{ name: 'app-inicio' }"
          variant="outline-danger"
          pill
        >
          <feather-icon
            icon="CornerUpLeftIcon"
            class="mr-50"
          />
          Voltar
        </b-button>
      </div>
    </b-col>
    <b-row
      v-if="!$can('admin')"
      style="margin: 30px;"
    >
      <b-col md="12">
        <tabela-produtos
          v-if="mostraProdutos"
          ref="tabelaProdutos"
          @add-cart="addProduto"
        />
        <tabela-pedidos
          v-show="!$can('vendedor')"
          ref="tabelaPedidos"
          @ocprodutos="ocultaProdutos"
          @adicionaPedidoAoCarrinho="adicionaPedidoAoCarrinho"
        />
      </b-col>
      <b-col md="4">
        <resumo-pedido
          ref="resumoPedido"
          @atualizapdv="atualizaPdv"
          @confirmapagamento="confirmapagamento"
          @limpar-pedido-cliente="limparPedidoDoCliente"
          @recarregaTabelaPedidos="recarregaTabelaPedidos"
        />
      </b-col>
      <b-col md="8">
        <pedido-cliente
          ref="pedidoCliente"
          :produtos="carrinho"
          @atualizapdv="atualizaPdv"
          @limpar-pedido-cliente="limparPedidoDoCliente"
        />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col md="12">
        <tabela-produtos
          v-if="mostraProdutos"
          ref="tabelaProdutos"
          @add-cart="addProduto"
        />
        <tabela-pedidos
          v-if="$can('admin')"
          ref="tabelaPedidos"
          @ocprodutos="ocultaProdutos"
          @adicionaPedidoAoCarrinho="adicionaPedidoAoCarrinho"
        />
      </b-col>
      <b-col md="4">
        <resumo-pedido
          ref="resumoPedido"
          @buscaNota="buscaNota"
          @buscaFiado="buscaFiado"
          @atualizapdv="atualizaPdv"
          @confirmapagamento="confirmapagamento"
          @limpar-pedido-cliente="limparPedidoDoCliente"
          @recarregaTabelaPedidos="recarregaTabelaPedidos"
          @adicionaPedidoAoCarrinho="adicionaPedidoAoCarrinho"
        />
      </b-col>
      <b-col md="8">
        <pedido-cliente
          ref="pedidoCliente"
          :produtos="carrinho"
          @atualizapdv="atualizaPdv"
          @limpar-pedido-cliente="limparPedidoDoCliente"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import carregaProdutos from '@/helpers/carregaProdutos'
import atualizaItensPedido from '@/helpers/atualizaItensPedido'
import TabelaProdutos from './components/tabela-produtos.vue'
import TabelaPedidos from './components/tabela-pedidos.vue'
import PedidoCliente from './components/pedido-cliente.vue'
import ResumoPedido from './components/resumo-pedido.vue'

export default {
  name: 'Pdva',
  components: {
    ResumoPedido,
    PedidoCliente,
    TabelaProdutos,
    TabelaPedidos,
  },
  data() {
    return {
      mostraCliente: false,
      mostraProdutos: this.$can('vendedor'),
      carrinho: [],
      mostranota: false,
      imp: {},
    }
  },
  async created() {
    this.limpaPedidoLocalStorage()
    const idPedido = await JSON.parse(localStorage.getItem('idPedido'))
    if (idPedido) {
      await this.$refs.tabelaPedidos.carregarPedido({ id: idPedido })
      localStorage.removeItem('idPedido')
    }
    await this.carregatodasAsConfigs()

    const impPad = await JSON.parse(localStorage.getItem('impressao') || '[]')
    // eslint-disable-next-line no-undef
    const impressorasLocalHost = await qz.printers.find()
    impPad.forEach(impre => {
      if (!impressorasLocalHost.includes(impre.padrao)) this.$toast.error('Impressora não conectada')
    })
  },
  methods: {
    async carregatodasAsConfigs() {
      // eslint-disable-next-line no-undef
      if (!qz.websocket.isActive()) {
        // eslint-disable-next-line no-undef,no-unused-vars
        await qz.security.setCertificatePromise((resolve, reject) => {
          resolve('-----BEGIN CERTIFICATE-----\n'
              + 'MIID6zCCAtOgAwIBAgIUX7m6uWuxPNE4JEsAuMcSiS5+l7MwDQYJKoZIhvcNAQEL\n'
              + 'BQAwgYMxCzAJBgNVBAYTAkJSMQ8wDQYDVQQIDAZQQVJBTkExETAPBgNVBAcMCENV\n'
              + 'UklUSUJBMQ4wDAYDVQQKDAVERVZOWDENMAsGA1UECwwEQkFDSzEOMAwGA1UEAwwF\n'
              + 'RkFCSU8xITAfBgkqhkiG9w0BCQEWEkZBQklPVEdLQEdNQUlMLkNPTTAgFw0yMjAx\n'
              + 'MTEyMzMyNDBaGA8yMDUzMDcwNjIzMzI0MFowgYMxCzAJBgNVBAYTAkJSMQ8wDQYD\n'
              + 'VQQIDAZQQVJBTkExETAPBgNVBAcMCENVUklUSUJBMQ4wDAYDVQQKDAVERVZOWDEN\n'
              + 'MAsGA1UECwwEQkFDSzEOMAwGA1UEAwwFRkFCSU8xITAfBgkqhkiG9w0BCQEWEkZB\n'
              + 'QklPVEdLQEdNQUlMLkNPTTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEB\n'
              + 'AMqiy0O8iu5T0xNn4eJ/eX6GYEkRcPUk2V97MNEEpxjT/2GjtohseidT5OmUPFke\n'
              + 'HAUM8gc5vjrnz3t9qRqyfxCInlRFLVKcP4OAfn9QVtPIBkYYqU0DtG0L/n3z1Lib\n'
              + 'zMT8wIxJNzue+6Ga+/kSGfsYL45RjxTkiJwiav5VMc+IcWLXti5ZEkaHgSkxcrQN\n'
              + 'rqNurCyxWVDsONWHNFWi7xfqN1KvhkXe5L3cQPhju1dz1kiv8FMjavO6zgsM4wIf\n'
              + 'y4au28zVsAkGwVAckcWckM2tG94PJGbtNQDBdYEL/C2DJYNLqQ+5abO5wWR5PBW2\n'
              + 'hinl4X97lrIRD6+qNu7wcZECAwEAAaNTMFEwHQYDVR0OBBYEFG2RMM0BAjkNHjbE\n'
              + 'CnJfZXLoyuRUMB8GA1UdIwQYMBaAFG2RMM0BAjkNHjbECnJfZXLoyuRUMA8GA1Ud\n'
              + 'EwEB/wQFMAMBAf8wDQYJKoZIhvcNAQELBQADggEBACoJvrk+7knxe5StldXv0PvA\n'
              + 'w8xq9nN0vjLegzdQ2Wh0KaNBr07bfx343dCTr9v+w9rtu3xtsaUUc82hYN+L9RVJ\n'
              + 'zuRwexaXoN50L7ff5wDGDWuaeL2ScEWNJcHndDMcXU7HYfTCUS2qh1CM1m+zve1j\n'
              + 'vyA3f7KamO4N1XNMlIJf7eDRufvgVSAUlf6FVwIQwTcuFONK1/q++7hVr5ACogoY\n'
              + 'pCLzR2JRpGVL7w53t+Sh2RtLNzXDhBIuI468liNr1mkSVJwXfxchx+gWJvsvHXaD\n'
              + 'xMWSm+AtCBFK2NgMRbcvn9VHgToDCrX3YMuBeuaAqVOhsFHjmf3mFuzB66ik6h0=\n'
              + '-----END CERTIFICATE-----\n')
        })
        // eslint-disable-next-line no-undef,no-unused-vars
        await qz.security.setSignatureAlgorithm('SHA512') // Since 2.1
        // eslint-disable-next-line no-undef
        await qz.websocket.connect()
      }
    },
    limpaPedidoLocalStorage() {
      localStorage.removeItem('itensPedido')
      localStorage.removeItem('resumopedido')
      localStorage.removeItem('pedido_id')
      localStorage.removeItem('pedido')
    },
    recarregaTabelaPedidos() {
      this.$refs.tabelaPedidos.caregaP()
    },
    confirmapagamento() {
      this.$refs.tabelaPedidos.carregaPedidos()
    },
    atualizaPdv() {
      this.carrinho = []
      this.atualizaCarrinho()
    },
    limparPedidoDoCliente() {
      if (this.$can('caixa') || this.$can('admin')) this.$refs.tabelaPedidos.mostraInfoCliente(false)
      this.carrinho = []
      this.$refs.pedidoCliente.atualizaItens(this.carrinho)
      this.$refs.resumoPedido.AtCarregaPedido()
      this.$refs.tabelaPedidos.caregaP()
      this.limpaPedidoLocalStorage()
    },
    async atualizaCarrinho() {
      this.mostraCliente = false
      this.carrinho = await carregaProdutos()
      this.$refs.pedidoCliente.atualizaItens(this.carrinho)
      await this.$refs.resumoPedido.AtCarregaPedido()
    },
    async adicionaPedidoAoCarrinho() {
      this.carrinho = await carregaProdutos()
      await this.$refs.tabelaPedidos.mostraInfoCliente(true)
      this.atualizaCarrinho()
    },
    ocultaProdutos() {
      this.mostraProdutos = !this.mostraProdutos
    },
    buscaNota() {
      this.$refs.refnota.buscaNota()
    },
    buscaFiado() {
      this.$refs.refFiado.buscaFiado()
    },
    async addProduto(item) {
      const produto = item
      this.carrinho = await carregaProdutos()
      // Adiciona pessoa ao cadastro
      const existe = this.carrinho.find(car => car.id === produto.id)
      if (!existe) {
        produto.qtd = 1
        this.carrinho.push(produto)
      } else {
        const chaveCarrinho = this.carrinho.findIndex((obj => obj.id === produto.id))
        this.carrinho[chaveCarrinho].qtd += 1
      }
      await atualizaItensPedido('produtos', this.carrinho)
      this.$refs.resumoPedido.adicionaPedidoAoCarrinho()
      this.$refs.pedidoCliente.atualizaItens(this.carrinho)
      await this.$refs.resumoPedido.buscaPedidoDoCarrinho()
    },
  },
}
</script>

<style scoped>

</style>
