<template>
  <b-modal
    id="opcoesprodutos"
    style="margin-top: 20px"
    title="Opções do Menu"
    hide-footer
  >
    <b-form-group label="Selecione as opções abaixo:">
      <b-form-checkbox-group
        id="checkbox-group-1"
        v-model="selected"
        :options="opcoes"
        name="flavour-1"
        class="demo-inline-spacing"
        @change="selecionaOpcao"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalOpcoesprodutos',
  data() {
    return {
      selected: [],
      opcoes: [],
      selecionados: [],
    }
  },
  created() {
    this.$http.get('/pdv/ppo').then(resp => {
      this.selected = resp.data.selected
      resp.data.opcoes.forEach(res => {
        this.opcoes.push({
          id: res.id,
          value: res.value,
          text: res.name,
        })
      })
      this.$emit('opcoestabela', this.selected)
    })
  },
  methods: {
    selecionaOpcao() {
      const opcoesSelecionadas = []
      this.selected.forEach(selecionados => {
        const busca = this.opcoes.filter(x => x.value === selecionados)
        if (busca.length > 0) {
          opcoesSelecionadas.push(busca[0].id)
        }
      })
      this.$http.post('pdv/ppo', { opcoes: opcoesSelecionadas })
      // localStorage.setItem('opcoes_menu', JSON.stringify(this.selected))
      this.$emit('opcoestabela', this.selected)
    },
  },
}
</script>

<style scoped>

</style>
