<template>
  <div>

    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col
              style="text-align: right;"
            >
              <b-button
                variant="flat-black"
                class="btn-icon"
              >
                <feather-icon
                  icon="SlackIcon"
                  @click="$emit('ocprodutos', true)"
                />
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div
                class="position-relative"
                style="line-height: 10px; font-size: 13px;"
              >
                <b-table
                  id="table-id"
                  ref="tabela-produtos"
                  :items="pedidos"
                  :fields="camposselecao"
                  sticky-header
                  responsive
                  bordered
                  no-border-collapse
                  show-empty
                  primary-key="id"
                  @row-dblclicked="carregarPedido"
                >
                  <template #cell(total_pedidos)="{item}">
                    {{ item.totalOrder | currency }}
                  </template>
                  <template #cell(statusId)="{item}">
                    {{ item.statusId === 1 ? 'Em Aberto' : item.statusId === 10 ? 'Orçamento': item.statusId === 11 ? 'Fiado' : 'Sem Status' }}
                  </template>
                </b-table>
                <b-overlay
                  :show="estaCarregando"
                  no-wrap
                  opacity="0.5"
                />
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <modal-opcoesprodutos />
    </b-row>
    <b-row
      v-if="cliente.name && mostracliente"
      class="mb-1"
    >
      <b-col>
        <b-input
          v-model="cliente.name"
          disabled
        />
      </b-col>
      <b-col>
        <b-input
          v-model="cliente.document"
          disabled
        />
      </b-col>
      <b-col>
        <b-input
          v-model="cliente.phone"
          disabled
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ModalOpcoesprodutos from './modal-opcoesprodutos.vue'
import CarregaDadosPedidos from '../../../../helpers/carregaDadosPedidos'
import CarregaCliente from '../../../../helpers/carregaCliente'

export default {
  name: 'TabelaProdutos',
  components: {
    ModalOpcoesprodutos,
  },
  data() {
    return {
      mostracliente: false,
      camposselecao: [
        {
          key: 'id',
          label: '#',
        },
        {
          key: 'customer.name',
          label: 'Nome Cliente',
        },
        {
          key: 'total_pedidos',
          label: 'Total Pedido',
        },
        {
          key: 'statusId',
          label: 'Status',
        },
      ],
      items: [],
      camposSelecionados: '',
      produtos: [],
      pedidos: [],
      cliente: {},
      pesquisaInicio: '',
      pesquisaMeio: '',
      currentPage: 0,
      perPage: 10,
      estaCarregando: false,
      proximaPagina: null,
      inicio: true,
      usaLocal: localStorage.getItem('usaLocal'),
    }
  },
  watch: {
    /* Optionally hide scrollbar when loading */
    estaCarregando(newVal, oldVal) {
      if (newVal !== oldVal) {
        const tableScrollBody = this.$refs['tabela-produtos'].$el
        if (newVal === true) {
          tableScrollBody.classList.add('overflow-hidden')
        } else {
          tableScrollBody.classList.remove('overflow-hidden')
        }
      }
    },
  },
  created() {
    this.carregaPedidos()
    this.inicio = false
  },
  mounted() {
    const tableScrollBody = this.$refs['tabela-produtos'].$el
    /* Consider debouncing the event call */
    tableScrollBody.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    /* Clean up just to be sure */
    const tableScrollBody = this.$refs['tabela-produtos'].$el
    tableScrollBody.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    async mostraInfoCliente(valor) {
      this.cliente = await CarregaCliente()
      this.mostracliente = valor
    },
    async carregarPedido(pedido) {
      await CarregaDadosPedidos(pedido.id)
      this.$emit('adicionaPedidoAoCarrinho', true)
    },
    async caregaP() {
      this.estaCarregando = true
      const pedidos = []
      const url = 'orders/getpdvorders'
      await this.$http.get(url).then(resp => {
        this.proximaPagina = resp.data.next_page_url
        resp.data.data.forEach(resposta => {
          pedidos.push(resposta)
        })
      })
      this.estaCarregando = false

      this.pedidos = pedidos
    },
    async carregaPedidos() {
      // Coloca loop de carregando
      if (!this.inicio && !this.proximaPagina) return
      this.estaCarregando = true
      let url = 'orders/getpdvorders'
      if (this.proximaPagina) url = this.proximaPagina
      await this.$http.get(url).then(resp => {
        this.proximaPagina = resp.data.links.next
        resp.data.data.forEach(resposta => {
          this.pedidos.push(resposta)
        })
      })
      this.estaCarregando = false
    },
    onScroll(event) {
      if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
        if (!this.estaCarregando) {
          this.carregaPedidos()
        }
      }
    },
  },
}

</script>

<style scoped>
  .b-table-sticky-header {
    overflow-y: auto;
    max-height: 225px;
  }

</style>
