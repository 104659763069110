import axiosIns from '@/libs/axios'

async function CarregaDadosPedidos(pedidoId) {
  const { data } = await axiosIns.get(`orders/find/${pedidoId}`)
  const produtos = []
  data.order_items.forEach(prod => {
    produtos.push({
      id: prod.products.id,
      name: prod.products.name,
      price: prod.unityAmout,
      // eslint-disable-next-line radix
      qtd: parseInt(prod.qunatity),
      status: 1,
    })
  })
  const obj = { pedido: data, cliente: data.customer, produtos }
  localStorage.setItem('itensPedido', JSON.stringify(obj))
  return obj
}

export default CarregaDadosPedidos
