<template>
  <b-card>
    <b-row>
      <b-col class="text-center">
        <h6>RESUMO DO PEDIDO</h6>
      </b-col>
    </b-row>
    <b-row style="margin-top: 10px">
      <b-col md="4">
        <span>Quantidade</span>
        <b-input
          disabled
          :value="quantidade"
        />
      </b-col>
      <b-col md="4">
        <span>Parc {{ pedidoParcelas }}x</span>
        <v-select
          v-model="pedidoParcelas"
          :clearable="false"
          :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          label="text"
          @input="calculaParcelamento"
        />
      </b-col>
      <b-col md="4">
        <span>Desconto</span>
        <b-input
          :value="pedidoDesconto | currency"
          @click="carregaDesconto"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <div style="margin-top: 10px">
          <span>Preço Total: {{ pedidoParcelas }}x
            {{ (pedidoTotal / pedidoParcelas) | currency }}</span>
        </div>
        <b-input
          disabled
          style="height: 70px; margin-top: 5px"
          :value="pedidoTotal | currency"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="6">
        <b-button
          v-if="pedidoId && ($can('admin') || $can('caixa'))"
          variant="success"
          class="mb-75"
          fixed
          :disabled="total === 0"
          block
          @click="efetuarPagamento"
        >
          Efetuar Pagamento
        </b-button>
      </b-col>
      <b-col md="6">
        <b-button
          variant="success"
          class="mb-75"
          fixed
          :disabled="total === 0"
          block
          @click="fecharPedido"
        >
          Salvar Pedido
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="AdcDesconto"
      style="margin-top: 20px"
      title="Adicionar Desconto"
      hide-footer
    >
      <b-row>
        <b-col>
          <div style="margin-top: 10px; margin-bottom: 4px">
            <span>Desconto em %</span>
          </div>
          <b-form-input
            v-model="porcentagem"
            style="height: 55px; margin-bottom: 10px"
            placeholder="Porcentagem"
            @input="calcPorcentagem($event)"
          />
        </b-col>
        <b-col>
          <div style="margin-top: 10px; margin-bottom: 4px">
            <span>Desconto em R$</span>
          </div>
          <b-form-input
            v-model="descontoemReais"
            style="height: 55px; margin-bottom: 10px"
            class="form-control"
            @input="calcularDescontoReais($event)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="margin-top: 10px; margin-bottom: 4px">
            <span>Valor Final</span>
          </div>
          <b-form-input
            style="height: 55px; margin-bottom: 10px"
            class="form-control"
            :value="descontoTotal | currency"
            @input="calcularDescontoTotal($event)"
          />
        </b-col>
      </b-row>

      <b-row class="mt-2 mb-2">
        <b-col>
          <b-button
            variant="primary"
            block
            @click="addDesconto"
          >
            Adicionar Desconto
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <modal-efetuar-pagamento
      ref="modalEfetuarPagamento"
      :tpedido="pedidoTotal"
      :pdesc="pedidoDesconto"
      :pparcelas="pedidoParcelas"
      :rpedido="resumopedido"
      @atualizapdv="$emit('atualizapdv', true)"
      @confirmapagamento="confirmapagamento"
      @limpar-pedido-cliente="limparPedidoDoCliente"
      @adicionaPedidoAoCarrinho="adicionaPedidoAoCarrinho"
    />
    <modal-fecharpedido
      ref="modalFecharPedido"
      :tpedido="pedidoTotal"
      :pdesc="pedidoDesconto"
      :pparcelas="pedidoParcelas"
      :imp="imp"
      @buscaNota="$emit('buscaNota', true)"
      @printNota="$emit('printNota', true)"
      @buscaFiado="$emit('buscaFiado', true)"
      @printFiado="$emit('printFiado', true)"
      @atualizapdv="$emit('atualizapdv', true)"
      @limpapedido="limpaDados"
      @limpar-pedido-cliente="$emit('limpar-pedido-cliente')"
      @adicionaPedidoAoCarrinho="adicionaPedidoAoCarrinho"
      @recarregaTabelaPedidos="$emit('recarregaTabelaPedidos')"
    />
  </b-card>
</template>

<script>
import { VMoney } from 'v-money'
import CarregaItensPedido from '@/helpers/carregaItensPedido'
import ResumoPedido from '@/helpers/carregaResumoPedido'
import carregaProdutos from '@/helpers/carregaProdutos'
import ModalFecharpedido from './modal-fecharpedido.vue'
import ModalEfetuarPagamento from './modal-efetuar-pagamento.vue'

export default {
  name: 'ResumoPedido',
  directives: { money: VMoney },
  components: {
    ModalFecharpedido,
    ModalEfetuarPagamento,
  },
  // @ts-ignore
  props: [
    // eslint-disable-next-line vue/require-prop-types
    'imp',
  ],
  data() {
    return {
      pedidoDesconto: 0,
      pedidoTotal: 0,
      pedidoParcelas: 1,
      quantidade: 0,
      total: 0,
      porcentagem: 0,
      descontoTotal: 0,
      ddTotal: 0,
      descontoemReais: 0,
      descontoemRr: 0,
      resumopedido: {},
      desconto: 0,
      parcelado: '',
      pedidoId: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      paymentInfos: {},
    }
  },
  async mounted() {
    await this.buscaPedidoDoCarrinho()
  },
  methods: {
    carregaDesconto() {
      this.descontoTotal = this.pedidoTotal
      this.addDesconto()
      this.$bvModal.show('AdcDesconto')
    },
    limparPedidoDoCliente() {
      this.$emit('limpar-pedido-cliente', true)
    },
    adicionaPedidoAoCarrinho() {
      this.$emit('adicionaPedidoAoCarrinho', true)
    },
    confirmapagamento() {
      this.$emit('confirmapagamento', true)
    },
    addDesconto() {
      if (
        typeof this.descontoemReais === 'string'
        || this.descontoemReais instanceof String
      ) {
        const desconto = this.descontoemReais.replaceAll(',', '.')
        this.descontoemReais = parseFloat(desconto).toFixed(2)
      }
      this.pedidoDesconto = this.descontoemReais === 0 ? this.descontoemRr : this.descontoemReais
      this.pedidoTotal = this.ddTotal === 0 ? this.descontoTotal : this.ddTotal
      this.calculaParcelamento()
      localStorage.setItem('desconto', JSON.stringify(this.descontoemReais))
      this.$bvModal.hide('AdcDesconto')
    },
    async efetuarPagamento() {
      const dados = await ResumoPedido()
      const obj = {
        pedidototal: this.pedidoTotal,
        pedidodesconto: this.pedidoDesconto,
        qtdparcelas: this.pedidoParcelas,
        valorparcela: this.parcelado,
        quantidade: this.quantidade,
        dados,
      }
      localStorage.setItem('resumopedido', JSON.stringify(obj))
      this.$refs.modalEfetuarPagamento.LimparCampos()
      this.$refs.modalEfetuarPagamento.verificaCarteira()
      this.$bvModal.show('efetuarpagamento')
    },
    fecharPedido() {
      const obj = {
        pedidototal: this.pedidoTotal,
        pedidodesconto: this.pedidoDesconto,
        qtdparcelas: this.pedidoParcelas,
        valorparcela: this.parcelado,
        quantidade: this.quantidade,
      }
      localStorage.setItem('resumopedido', JSON.stringify(obj))
      this.$refs.modalFecharPedido.pegaDetalhesPedido()
      this.$bvModal.show('fecharpedido')
    },
    limpaDados() {
      this.pedidoDesconto = 0
      this.pedidoTotal = 0
      this.pedidoParcelas = 1
      this.quantidade = 0
      this.total = 0
      this.porcentagem = 0
      this.descontoTotal = 0
      this.ddTotal = 0
      this.descontoemReais = 0
      this.descontoemRr = 0
      this.resumopedido = {}
      this.desconto = 0
      this.parcelado = ''
      this.pedidoId = null
    },
    calcPorcentagem(texto) {
      // eslint-disable-next-line radix
      if (parseInt(texto) > 100) {
        this.porcentagem = 0
        return this.$toast.error('Não pode ser maior que 100')
      }
      const vtotal = (texto / 100) * this.total
      this.descontoemReais = vtotal
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      const destotal = this.total - this.descontoemReais
      this.descontoTotal = destotal
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      return true
    },
    calcularDescontoReais(valor) {
      const nvalor = valor.replace(',', '.')
      this.ddTotal = 0
      this.porcentagem = ((Number(nvalor) / this.total) * 100).toFixed(2)
      const total = this.total - valor.replace(',', '.')
      this.descontoTotal = total.toFixed(2)
      this.descontoemRr = Number(nvalor).toFixed(2)
    },
    calcularDescontoTotal(valor) {
      const nvalor = Number(valor.replace(',', '.')).toFixed(2)
      this.ddTotal = nvalor
      const dr = this.total - nvalor
      this.descontoemReais = dr.toFixed(2)
      this.porcentagem = (
        ((nvalor - this.total) / this.total)
        * 100
        * -1
      ).toFixed(2)
    },
    calculaParcelamento() {
      this.parcelado = this.pedidoTotal / this.pedidoParcelas
    },
    async AtCarregaPedido() {
      this.pedidoId = null
      let qtd = 0
      let total = 0
      const carrinho = await carregaProdutos()
      if (carrinho.length === 0) {
        this.quantidade = 0
        this.total = 0
        this.pedidoTotal = 0
        this.descontoTotal = 0
        this.pedidoDesconto = 0
        this.parcelado = 0
      } else {
        carrinho.forEach(car => {
          qtd += car.qtd
          total += car.qtd * car.price
        })
        this.quantidade = qtd
        this.pedidoTotal = total
        this.total = total
      }

      const itensPed = await CarregaItensPedido()
      if (itensPed.pedido) {
        this.pedidoId = itensPed.pedido.id
        const desconto = itensPed.pedido.discount
        this.pedidoDesconto = parseFloat(desconto).toFixed(2)
        this.pedidoParcelas = parseFloat(itensPed.pedido.numberInst).toFixed(0)
      }
    },
    async buscaPedidoDoCarrinho() {
      let qtd = 0
      let total = 0
      const carrinho = await carregaProdutos()
      if (carrinho.length === 0) {
        this.quantidade = 0
        this.total = 0
        this.pedidoTotal = 0
        this.descontoTotal = 0
      } else {
        carrinho.forEach(car => {
          qtd += car.qtd
          total += car.qtd * car.price
        })
        this.quantidade = qtd
        this.total = total
        this.pedidoTotal = total
        this.descontoTotal = total
      }
      this.calculaParcelamento()
    },
  },
}
</script>

<style scoped>
</style>
