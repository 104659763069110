<template>
  <b-modal
    id="efetuarpagamento"
    style="margin-top: 20px"
    title="Efetuar Pagamento"
    size="lg"
    hide-footer
    no-close-on-backdrop
  >
    <b-row v-if="cliente">
      <b-col md="6">
        <b-form-group
          label="CPF/CNPJ do Cliente"
          label-for="cpf"
        >
          <b-form-input
            id="cpf"
            :value="cliente.cpf"
            disabled
            placeholder="CPF/CNPJ"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Nome do Cliente"
          label-for="nome"
        >
          <b-form-input
            id="nome"
            :value="cliente.nome"
            disabled
            placeholder="Nome do Cliente"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group
          label="Total do Pedido"
          label-for="totalpedido"
        >
          <b-form-input
            id="totalpedido"
            :value="tpedido | currency"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Total Pago"
          label-for="totalpago"
        >
          <b-form-input
            id="totalpago"
            :value="totalPago | currency"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          :label="saldoRestante() < 0 ? 'Troco' : 'Saldo Restante'"
          label-for="saldo_restante"
        >
          <b-form-input
            id="saldo_restante"
            :value="saldoRestante() | currency"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      style="margin-top: 10px; text-align: center"
    >
      <b-col
        md="4"
      >
        Forma de Pagamento
      </b-col>
      <b-col
        md="3"
      >
        Qtd Vezes
      </b-col>
      <b-col
        md="3"
        style="padding-left: 2px; padding-right:0"
      >
        Valor Pago
      </b-col>
      <b-col
        md="2"
      >
        <b-button
          variant="success"
          class="btn-icon"
          @click="adicionarHorario"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>

      </b-col>
    </b-row>
    <b-row
      v-for="(hv, key) in mformaspagamento"
      :key="key"
      style="margin-top: 10px"
    >
      <b-col
        md="4"
      >
        <v-select
          v-model="hv.pgselecionado"
          label="name"
          :options="formaspagamento"
          @input="liberaVezes($event, key)"
        />
      </b-col>
      <b-col
        md="3"
      >
        <v-select
          v-model="hv.pgvezes"
          label="nome"
          :disabled="!hv.liberaVz"
          :options="hv.pgOpVz"
        />
      </b-col>
      <b-col
        md="3"
      >
        <money
          v-model="hv.valor"
          class="form-control"
          placeholder="Valor"
        />
      </b-col>
      <b-col
        md="2"
        style="padding-left: 0"
      >

        <b-button
          variant="flat-danger"
          class="btn-icon"
          @click="exluirValor(key)"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </b-col>
      <b-col md="12">
        Preço Parcela : {{ hv.pgvezes }} x {{ formatInstValue(hv.valor, hv.pgvezes) }}
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="4"
        class="mt-5 mb-lg-5"
      >
        <b-button
          block
          variant="success"
          @click="calcularTotal"
        >
          Calcular total
        </b-button>
      </b-col>
      <b-col
        md="4"
        class="mt-5 mb-lg-5"
      >
        <b-button
          block
          variant="success"
          :disabled="(tpedido - totalPago) > 0"
          @click="salvarPagamento"
        >
          Concluir Pagamento
        </b-button>
      </b-col>
      <b-col
        md="4"
        class="mt-5 mb-lg-5"
      >
        <b-button
          block
          variant="gradient-warning"
          :disabled="!mostrarFiado"
          @click="PagFiado"
        >
          Carteira Fiado
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import 'vue-loading-overlay/dist/vue-loading.css'
import CarregaCliente from '@/helpers/carregaCliente'
import ItensPedido from '@/helpers/carregaItensPedido'
import carregaProdutos from '@/helpers/carregaProdutos'

export default {
  name: 'ModarEfetuarPagamento',
  components: {
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['tpedido', 'pdesc', 'pparcelas'],
  data() {
    return {
      isLoading: false,
      fullPage: true,
      id: null,
      totaldoPedido: this.tpedido,
      totalPago: 0,
      mostraPopUp: false,
      emailCliente: '',
      resumopedido: [],
      addEndEntrega: false,
      formaspagamento: [],
      pgSelecionado: [],
      mformaspagamento: [{
        pgselecionado: '',
        pgvezes: '',
        valor: '',
        liberaVz: false,
        pgOpVz: [],
      }],
      user_id: '',
      mostrarFiado: false,
      cliente: {},
    }
  },
  async created() {
    this.LimparCampos()
    this.Pegadetalhes()
  },
  async mounted() {
    this.$http.get('payments/methods').then(resp => {
      this.formaspagamento = resp.data
    })
    await this.buscaImpressora(1)
  },
  methods: {
    formatInstValue(value, vz) {
      return (value / vz).toFixed(2)
    },
    liberaVezes(event, key) {
      if (event.vezes === 1) {
        this.mformaspagamento[key].liberaVz = false
        this.mformaspagamento[key].pgvezes = 1
      } else {
        this.mformaspagamento[key].liberaVz = true
        for (let i = 1; i <= event.vezes; i++) { // eslint-disable-line
          this.mformaspagamento[key].pgOpVz.push(i)
        }
      }
    },
    saldoRestante() {
      const valor = this.tpedido - this.totalPago
      return valor.toFixed(2)
    },
    calcularTotal() {
      let totalPago = 0
      this.mformaspagamento.forEach(pg => {
        totalPago += pg.valor
      })
      this.totalPago = totalPago.toFixed(2)
    },
    PagFiado() {
      this.mformaspagamento.push({ pgselecionado: this.formaspagamento[5], pgvezes: 1, valor: this.tpedido })
      this.salvarPagamento()
    },
    async verificaCarteira() {
      let mFiado = false
      const cliente = await CarregaCliente()
      if (cliente.wallet) mFiado = cliente.wallet
      this.cliente = cliente
      this.mostrarFiado = mFiado
    },
    async salvarPagamento() {
      // this.$loading(true)
      if (this.pgSelecionado === []) return this.$toast.error('Preencha os campos de pagamento')
      const itens = await ItensPedido()
      const pagamento = {
        pagamento: this.mformaspagamento,
        pedido: itens.pedido,
        cliente: itens.cliente,
        produtos: itens.produtos,
      }
      this.$bvModal.hide('efetuarpagamento')
      await this.$http.post('payments/order', pagamento)
      localStorage.setItem('resumopedido', JSON.stringify([]))
      localStorage.removeItem('desconto')
      localStorage.removeItem('itensPedido')
      this.$emit('limpar-pedido-cliente', true)
      this.$emit('adicionaPedidoAoCarrinho', true)
      // this.$loading(false)
      this.$toast.success('Pagamento Concluido')
      await this.funcaoImprimir(itens.pedido.id, 2)
      return true
    },
    async funcaoImprimir(idPedido, impressora) {
      const imp = await this.buscaImpressora(impressora)
      const { data } = await this.$http.post('printer/order', { tipo: impressora, pedido: idPedido })
      // eslint-disable-next-line no-undef
      const config = qz.configs.create(imp)
      // eslint-disable-next-line no-undef
      return qz.print(config, [{
        type: 'pixel',
        format: 'html',
        flavor: 'plain',
        data,
      }])
    },
    async buscaImpressora(impressora) {
      const impressorapadrao = await JSON.parse(localStorage.getItem('impressao') || '[]')
      const impFiado = impressorapadrao.find(imp => imp.id === impressora)
      if (!impFiado) return this.$toast.error('Falta configurar a Impressora de Orçamento')
      return impFiado.padrao
    },
    adicionarHorario() {
      this.mformaspagamento.push({
        pgselecionado: '',
        pgvezes: '',
        valor: '',
        liberaVz: false,
        pgOpVz: [],
      })
    },
    exluirValor(key) {
      this.mformaspagamento.splice(key, 1)
    },
    async Pegadetalhes() {
      this.todosprodutos = await carregaProdutos()
      this.resumopedido = JSON.parse(localStorage.getItem('resumopedido'))
    },
    recebeuCliente(cliente) {
      this.mostraCadastro = true
      if (!cliente.id) return
      this.id = cliente.id
      this.cCPF = cliente.cpf
      this.cNome = cliente.name
      this.cTELEFONE = cliente.telefone
      this.cEMAIL = cliente.email
      this.endereco = cliente.endereco
      this.contato_entrega = cliente.contato_entrega
      this.cep = cliente.cep
      this.referencia = cliente.referencia
      this.carteira = cliente.carteira
    },
    SalvarCliente() {
      this.Pegadetalhes()
      const obj = {
        id: this.id,
        cpf: this.cCPF,
        telefone: this.cTELEFONE,
        nome: this.cNome,
        email: this.cEMAIL,
        endereco: this.endereco,
        contato_entrega: this.contato_entrega,
        cep: this.cep,
        referencia: this.referencia,
        todosprodutos: this.todosprodutos,
        total_pedidos: this.resumopedido.pedidototal,
        pedidodesconto: this.resumopedido.pedidodesconto,
        qtdparcelas: this.resumopedido.qtdparcelas,
        valorparcela: this.resumopedido.valorparcela,
      }
      if (this.id === null) {
        this.$http.post('clientes', obj)
          .then(resp => {
            if (resp.data.success) {
              this.$toast.success(resp.data.success)
            }
            if (resp.data.error) {
              this.$toast.success(resp.data.error)
            }
          })
      }
      if (this.id) {
        this.$http.post('clientes', obj)
          .then(resp => {
            if (resp.data.success) {
              localStorage.setItem('resumopedido', JSON.stringify({}))
              this.$toast.success(resp.data.success)
              this.$emit('atualizapdv', true)
            }
            if (resp.data.error) {
              this.$toast.success(resp.data.error)
            }
          })
      }
      this.$bvModal.hide('fecharpedido')
      this.LimparCampos()
      this.mostraCadastro = false
      this.addEndEntrega = false
    },
    LimparCampos() {
      this.id = null
      this.cNome = ''
      this.cTELEFONE = ''
      this.cEMAIL = ''
      this.cCPF = ''
      this.endereco = ''
      this.contato_entrega = ''
      this.cep = ''
      this.referencia = ''
      this.mformaspagamento = []
      this.totaldoPedido = this.tpedido
      this.totalPago = 0
      this.resumopedido = []
    },
  },
}
</script>

<style scoped>

</style>
